import { AnyAction, Dispatch } from "redux";
import moment from "moment";
import { saveAs } from "file-saver";
import { ThunkDispatch } from "redux-thunk";
import { amrPipelineService } from "../services/amr-pipeline.service";
import {
    amrPipelineDetailedActions as actionTypes,
    constants,
    SORT,
    gridColumns,
    roles,
    routes
} from "../constants";
import { errorActions } from "./error.actions";
import { history } from "../history";
import { AppState } from "../types/state/AppState";
import { requestCancelationController } from "../services/request-cancelation-controller";
import { OriginatingTransaction } from "../types/amr-pipeline/models/OriginatingTransaction";
import { AmrTransaction } from "../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransactionDocument } from "../types/amr-pipeline/models/OriginatingTransactionDocument";
import { pushNotificationService } from "../services";
import { DocumentType, getDocumentTypeText } from "../types/amr-pipeline/enums/DocumentType";
import { gridActions } from "./grid.actions";
import { GridColumn } from "../types/state/GridState";
import { ioiFloaterIndexes } from "../types/amr-pipeline/enums/IoIFloaterIndex";
import { OriginatingTransactionClass } from "../types/amr-pipeline/models/OriginatingTransactionClass";
import { OriginatingTransactionClassStatus, transactionClassStatusTitles } from "../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { PipelineDetailedTabTypes } from "../components/amrPipeline/types/PipelineDetailedTabTypes";
import { getSearchedTransactionDocuments, hasInvitedClientsAccess, hasLimitedAccess, isIoIonDisabledClass, transactionHasArrangerProSubscription } from "../utils/amr-pipeline.utils";
import { logger } from '../logging/logger';
import { TransactionAccessType } from '../types/amr-pipeline/enums/TransactionAccessType';
import { user } from "../user";
import { SubscriptionFeature } from "../types/billing/SubscriptionFeature";
import { TransactionResponse } from "../types/amr-pipeline/models/TransactionResponse";
import { amrCompaniesService } from '../services/amr-companies.service';
import { dealsService } from "../services/deals.service";

export const amrPipelineDetailedActions = {
    init,
    loadTransactions,
    loadInitialTransaction,
    transactionsSearchTermChange,
    searchTermChange,
    documentsSearchTermChange,
    loadTransactionDetails,
    storeTransactionDetails,
    transactionDetailsRequesting,
    transactionsRequesting,
    downloadIntexFile,
    downloadManagerPresentationFile,
    downloadDisclosureFile,
    downloadDealDocument,
    downloadTransactionDocument,
    downloadTransactionDocuments,
    downloadDealDocuments,
    downloadDealRegularDocuments,
    exportPortfolio,
    editIOIs,
    submitIOIs,
    showGridIOIs,
    readIOIsByUser,
    setUnreadIOIsRead,
    exportIOIs,
    showInvestorPopup,
    hideInvestorPopup,
    reset,
    logTabAccess,
};

type TDispatch = ThunkDispatch<AppState, void, AnyAction>;

function init(dealReferenceName: string, transactionReferenceName: string, token?: string) {
    return async (dispatch: TDispatch, getState: () => AppState) => {
        const isInvitedClient = user.hasSingleRole(roles.ArrangersClient);

        if (token) {
            try {
                if (isInvitedClient) {
                    await amrPipelineService.updateArrangerClient(token);
                } else {
                    await amrPipelineService.logAccessToSharedTransaction(dealReferenceName, transactionReferenceName, token);
                }
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            }
        }

        Promise.all([
            dispatch(loadTransactions()),
            dispatch(loadInitialTransaction(dealReferenceName, transactionReferenceName)),
        ]);
    };
}

function loadTransactions(tab: PipelineDetailedTabTypes = PipelineDetailedTabTypes.all) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const {
            amrPipeline: {
                deals: {
                    dealSortBy,
                    dealSortDirection,
                }
            },
            amrPipelineDetailed: {
                offset,
                searchTerm,
                isTransactionsRequesting,
            }
        } = getState().issuanceMonitor;

        const filterCriteria = {
            ...constants.defaultTransactionsSearchCriteria,
            searchTerm: searchTerm?.toLowerCase(),
            hasIois: tab === PipelineDetailedTabTypes.ioi ? true : undefined,
            offset,
        };

        const searchCriteria = [
            {
                field: dealSortBy,
                ascending: dealSortDirection === SORT.ASC,
            },
            ...constants.defaultDealOrderCriteria,
        ];

        if (isTransactionsRequesting) {
            requestCancelationController.abort(history.location.pathname);
            dispatch(transactionsRequesting(false));
        }

        try {
            dispatch(transactionsRequesting(true));
            const transactions: TransactionResponse = await amrPipelineService.getTransactionsList(
                filterCriteria,
                searchCriteria
            );

            dispatch(storeTransactions(transactions, searchTerm));
            dispatch(transactionsRequesting(false));
        } catch (e) {
            dispatch(errorActions.criticalError(e));
            if (!errorActions.isRequestCancelationError(e)) {
                dispatch(transactionsRequesting(false));
            }
        }
    };
}

function loadInitialTransaction(
    dealReferenceName: string,
    transactionReferenceName: string
) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const {
            issuanceMonitor: {
                amrPipelineDetailed: {
                    initialTransaction
                }
            }
        } = getState();

        if (initialTransaction?.referenceName !== transactionReferenceName) {
            dispatch(transactionsRequesting(true));
            try {
                const transaction = await amrPipelineService.getTransaction(
                    dealReferenceName,
                    transactionReferenceName
                );

                if (!transaction) {
                    history.replace(routes.transactionLimitedAccess);
                    return;
                }

                dispatch(storeInitialTransaction(transaction));
            } catch (e) {
                if ([404, 403].includes(e.status)) {
                    history.replace(routes.AMRPipeline);
                } else {
                    dispatch(errorActions.criticalError(e));
                }
            } finally {
                dispatch(transactionsRequesting(false));
            }
        }
    };
}

function transactionsSearchTermChange(tab: PipelineDetailedTabTypes) {
    return async (dispatch: TDispatch, getState: () => AppState) => {
        const { isTransactionDetailsRequesting } = getState().issuanceMonitor.amrPipelineDetailed;
        if (isTransactionDetailsRequesting) {
            requestCancelationController.abort(history.location.pathname);
            dispatch(transactionDetailsRequesting(false));
        }
        dispatch(resetTransactions());
        dispatch(loadTransactions(tab));
    };
}

function resetTransactions() {
    return {
        type: actionTypes.RESET_TRANSACTIONS,
    };
}

function documentsSearchTermChange(
    searchTerm: string,
    transactionReferenceName: string
) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const {
            issuanceMonitor: {
                amrPipelineDetailed: {
                    loadedTransactionsWithDetails,
                    initialTransaction,
                }
            }
        } = getState();

        if (loadedTransactionsWithDetails) {
            const selectedTransaction =
                loadedTransactionsWithDetails?.find(
                    (t) => t.referenceName === transactionReferenceName
                ) || initialTransaction;

            if (selectedTransaction) {
                const {
                    documents,
                } = selectedTransaction as OriginatingTransaction;
                const filteredDocuments = getSearchedTransactionDocuments(documents, searchTerm);
                dispatch(storeDocumentsSearchResults(filteredDocuments));
            }
        }
    };
}

function loadTransactionDetails(
    dealReferenceName: string,
    transactionReferenceName: string,
    loadIOIs: boolean
) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const {
            issuanceMonitor: {
                amrPipelineDetailed: {
                    loadedTransactionsWithDetails,
                    initialTransaction,
                },
                amrPipelineCommon: {
                    userCompany,
                },
            },
        } = getState();

        const hasImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);

        if (loadedTransactionsWithDetails.some(t => t.referenceName === transactionReferenceName)
        ) {
            return;
        }

        dispatch(transactionDetailsRequesting(true));

        try {
            const transaction = transactionReferenceName === initialTransaction?.referenceName
                ? initialTransaction
                : await amrPipelineService.getTransaction(
                    dealReferenceName,
                    transactionReferenceName
                );

            const limitedAccess = hasLimitedAccess(transaction, userCompany);
            const limitedClientsAccess = !hasInvitedClientsAccess(transaction, userCompany);
            const hasArrangerProSubscription = transactionHasArrangerProSubscription(transaction);
            const withoutImAndArrangerProSubscriptions = !hasImSubscription && !hasArrangerProSubscription;

            const isMedia = user.hasRoles(roles.Media);

            const portfolioPromise = limitedAccess || isMedia || withoutImAndArrangerProSubscriptions
                ? null
                : amrPipelineService.getPortfolio(
                    dealReferenceName,
                    transactionReferenceName
                );

            const documentsPromise = limitedAccess || isMedia
                ? null
                : amrPipelineService.getDocuments(
                    dealReferenceName,
                    transactionReferenceName
                );

            const collateralQualityTestsPromise = limitedAccess
                ? null
                : amrPipelineService.getCollateralQualityTest(
                    dealReferenceName,
                    transactionReferenceName
                );

            const syndicateContactsPromise = amrPipelineService.getSyndicateContacts(
                dealReferenceName,
                transactionReferenceName
            );

            const ioisPromise = limitedAccess || isMedia || !loadIOIs
                ? null
                : amrPipelineService.getIOIs(
                    dealReferenceName,
                    transactionReferenceName
                );

            const intexFilePromise = limitedAccess || isMedia || withoutImAndArrangerProSubscriptions
                ? null
                : amrPipelineService.getIntexFile(dealReferenceName, transactionReferenceName);

            const managerPresentationFilePromise = limitedAccess || isMedia
                ? null
                : amrPipelineService.getManagerPresentationFile(dealReferenceName, transactionReferenceName);

            const disclosureFilePromise = isMedia
                ? null
                : amrPipelineService.getDisclosureFile(dealReferenceName, transactionReferenceName);

            const invitedClientsPromise = limitedClientsAccess
                ? null
                : amrPipelineService.getSharedClients(dealReferenceName, transactionReferenceName);

            const [
                portfolio,
                documents,
                collateralQualityTests,
                syndicateContacts = [],
                iois,
                intexFile,
                managerPresentationFile,
                disclosureFile,
                invitedClients,
            ] = await Promise.all([
                portfolioPromise,
                documentsPromise,
                collateralQualityTestsPromise,
                syndicateContactsPromise,
                ioisPromise,
                intexFilePromise,
                managerPresentationFilePromise,
                disclosureFilePromise,
                invitedClientsPromise,
            ]);

            dispatch(
                storeTransactionDetails(transactionReferenceName, {
                    ...transaction,
                    intexFile,
                    managerPresentationFile,
                    disclosureFile,
                    syndicateContacts,
                    portfolio,
                    documents,
                    collateralQualityTests,
                    invitedClients,
                    isDetailsLoaded: true
                })
            );
            dispatch({ type: actionTypes.STORE_IOIS, transactionReferenceName, dataItems: iois });
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        } finally {
            dispatch(transactionDetailsRequesting(false));
        }
    };
}

function downloadIntexFile(
    dealReferenceName: string,
    transactionReferenceName: string,
    fileName: string
) {
    return () => {
        amrPipelineService
            .downloadIntexFile(dealReferenceName, transactionReferenceName)
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, fileName)
            );
    };
}

function downloadManagerPresentationFile(
    dealReferenceName: string,
    transactionReferenceName: string,
    fileName: string
) {
    return () => {
        amrPipelineService
            .downloadManagerPresentationFile(
                dealReferenceName,
                transactionReferenceName
            )
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, fileName)
            );
    };
}

function downloadDisclosureFile(
    dealReferenceName: string,
    transactionReferenceName: string,
    fileName: string
) {
    return () => {
        amrPipelineService
            .downloadDisclosureFile(
                dealReferenceName,
                transactionReferenceName
            )
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, fileName)
            );
    };
}

function downloadDealDocument(
    dealReferenceName: string,
    referenceName: string,
    fileName: string
) {
    return () => {
        dealsService
            .getDealDocument(dealReferenceName, referenceName)
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, fileName)
            );
    };
}

function downloadTransactionDocument(
    dealReferenceName: string,
    transactionReferenceName: string,
    referenceName: string,
    fileName: string
) {
    return () => {
        amrPipelineService
            .getTransactionDocument(
                dealReferenceName,
                transactionReferenceName,
                referenceName
            )
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, fileName)
            );
    };
}

function downloadTransactionDocuments(
    dealReferenceName: string,
    transactionReferenceName: string,
    fileName: string,
) {
    return () => {
        amrPipelineService
            .getTransactionDocuments(
                dealReferenceName,
                transactionReferenceName
            )
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, fileName)
            );
    };
}

function downloadDealRegularDocuments(dealReferenceName: string, dealTicker: string) {
    return () => {
        dealsService
            .getDealRegularDocuments(dealReferenceName)
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, `${dealTicker} Deal Documents.zip`)
            );
    };
}

function downloadDealDocuments(
    dealReferenceName: string,
    dealTicker: string,
    documentType: DocumentType
) {
    return () => {
        dealsService
            .getDealDocumentsByType(dealReferenceName, documentType)
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, `${dealTicker} ${getDocumentTypeText(documentType)}.zip`)
            );
    };
}

function exportPortfolio(dealReferenceName: string, transactionReferenceName: string, dealLegalName: string) {
    return () => {
        amrPipelineService
            .exportPortfolio(dealReferenceName, transactionReferenceName)
            .then((file: { blob: Blob; type: string }) =>
                saveAs(
                    file.blob,
                    `${dealLegalName} Target Portfolio ${moment().format(
                        constants.dateFormat
                    )}.csv`
                )
            );
    };
}

function exportIOIs(dealReferenceName: string, transactionReferenceName: string, excludeRemovedClass?: boolean) {
    return () => {
        amrPipelineService
            .exportIOIs(dealReferenceName, transactionReferenceName, excludeRemovedClass)
            .then((file: { blob: Blob; type: string }) =>
                saveAs(file.blob, `${dealReferenceName} IOIs ${moment().format(constants.dateFormat)}.xlsx`),
            );
    };
}

function showGridIOIs(transactionReferenceName: string) {
    return async (dispatch: TDispatch, getState: () => AppState) => {
        const {
            issuanceMonitor: {
                amrPipelineDetailed: {
                    loadedTransactionsWithDetails,
                    initialTransaction
                }
            }
        } = getState();

        const currentTransaction = (loadedTransactionsWithDetails?.find(t => t.referenceName === transactionReferenceName) as OriginatingTransaction) || initialTransaction;
        const dataItems = currentTransaction.iois.filter(ioi => !ioi.isDeleted && !isIoIonDisabledClass(ioi, currentTransaction.classes)) ?? [];

        const classReferenceNameColumn = {
            ...gridColumns.classReferenceName,
            selectSourceItemsCallback: () =>
                currentTransaction.classes?.map(c => ({
                    key: c.referenceName,
                    title:
                        c.originatingTransactionClassStatus &&
                        c.originatingTransactionClassStatus !==
                            OriginatingTransactionClassStatus.Open
                            ? `${c.name} (${
                                  transactionClassStatusTitles[
                                      c.originatingTransactionClassStatus
                                  ]
                              })`
                            : c.name,
                    disabled:
                        c.originatingTransactionClassStatus === OriginatingTransactionClassStatus.NotOffered ||
                        c.originatingTransactionClassStatus === OriginatingTransactionClassStatus.Subject,
                })),
            updateDependencyColumnsCallback: (index: number) =>
                updateReadOnlyIOIColumns(index, transactionReferenceName),
        };

        const floaterIndexColumn: GridColumn = {
            ...gridColumns.ioiFloaterIndex,
            type: 'select',
            selectSourceItemsCallback: () => ioiFloaterIndexes
        };

        const columns = gridColumns.iois().map((c) => {
            if (c.name === gridColumns.classReferenceName.name) {
                return classReferenceNameColumn;
            }

            if (c.name === gridColumns.ioiFloaterIndex.name) {
                return floaterIndexColumn;
            }

            return c;
        }
        );

        dispatch(editIOIs(true));
        dispatch(gridActions.reset());
        dispatch(gridActions.init(dataItems, columns, 100, 25));
    };
}

function updateReadOnlyIOIColumns(
    gridItemIndex: number,
    transactionReferenceName: string
) {
    return async (dispatch: TDispatch, getState: () => AppState) => {
        const {
            issuanceMonitor: {
                amrPipelineDetailed: {
                    loadedTransactionsWithDetails,
                    initialTransaction
                },
            },
        } = getState();

        const currentTransaction = loadedTransactionsWithDetails?.find(
            (t) => t.referenceName === transactionReferenceName
        ) as OriginatingTransaction || initialTransaction as OriginatingTransaction;

        const availableClassesForIOIs = currentTransaction.classes;

        const { dataItems } = getState().grid;
        const row = dataItems[gridItemIndex];

        if (availableClassesForIOIs) {
            const className = row.classReferenceName
            const selectedClass = availableClassesForIOIs.find(
                (f) => f.name === className
            );
            const propsShouldBeUpdated = [
                "floaterIndex",
                "guidance",
                "subscription",
                "balance",
            ];
            if (selectedClass) {
                propsShouldBeUpdated.forEach(
                    (v) => (row[v] = selectedClass[v as keyof OriginatingTransactionClass])
                );
                dispatch(gridActions.deleteRow(gridItemIndex));
                row.ioiRatings = `${
                    selectedClass.expectedRatingMoodys ||
                    constants.emptyPlaceholder
                }/${
                    selectedClass.expectedRatingSnP ||
                    constants.emptyPlaceholder
                }/${
                    selectedClass.expectedRatingFitch ||
                    constants.emptyPlaceholder
                }/${
                    selectedClass.expectedRatingKbra ||
                    constants.emptyPlaceholder
                }/${
                    selectedClass.expectedRatingDbrs ||
                    constants.emptyPlaceholder
                }`;

                dispatch(gridActions.insertDataItems(row, gridItemIndex));
            }
        }
    };
}

function submitIOIs(dealReferenceName: string, transactionReferenceName: string, dealLegalName: string, activeTab?: PipelineDetailedTabTypes) {
    return async (dispatch: TDispatch, getState: () => AppState) => {
        dispatch(gridActions.validate());

        const grid = getState().grid;
        const isAnyIoI = grid.dataItems.some((i: {draft: boolean}) => !i.draft);

        if (grid.isValid || !isAnyIoI) {
            dispatch(ioisSubmitting(true));

            try {
                const ioisForSubmit = grid.dataItems.filter((item: { draft: boolean }) => !item.draft) ?? [];
                const notificationSubj =
                    ioisForSubmit.length
                        ? `IOIs have been submitted for ${dealLegalName}`
                        : `All IOIs have been deleted for ${dealLegalName}`;
                const notificationBody = ioisForSubmit.length
                    ? `Your IOIs for ${dealLegalName} have been submitted and can be accessed by arranger.`
                    : `All of your IOIs for ${dealLegalName} have been deleted.`;

                await amrPipelineService.submitIOIs(dealReferenceName, transactionReferenceName, ioisForSubmit);

                pushNotificationService.sendNotification(
                    "SendNotification",
                    notificationSubj,
                    notificationBody
                );

                const iois = await amrPipelineService.getIOIs(
                    dealReferenceName,
                    transactionReferenceName
                );

                if (activeTab === PipelineDetailedTabTypes.ioi) {
                    dispatch(resetTransactions());
                    dispatch(loadTransactions(activeTab));
                }

                dispatch({type: actionTypes.STORE_IOIS, transactionReferenceName, dataItems: iois});

                dispatch(editIOIs(false));
            } catch(e) {
                dispatch(errorActions.error(e));
            } finally {
                dispatch(ioisSubmitting(false));
            }
        }
    };
}

function showInvestorPopup(referenceName: string) {
    return async (dispatch: TDispatch) => {
        try {
            const [company, members] = await Promise.all([
                amrCompaniesService.getCompanyInfo(referenceName),
                amrCompaniesService.getMembersList(referenceName),
            ]);
            let companyWithMembers = { company, members };

            dispatch({
                type: actionTypes.SHOW_INVESTOR_POPUP,
                company: companyWithMembers,
                isPopupVisible: true,
            });
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    };
}

function hideInvestorPopup() {
    return {
        type: actionTypes.SHOW_INVESTOR_POPUP,
        isCompanyPopupVisible: false,
    };
}

function editIOIs(isEdit: boolean) {
    return {
        type: actionTypes.EDIT_IOIS,
        isEdit,
    };
}

function readIOIsByUser(dealReferenceName: string, transactionReferenceName: string, ioisReferenceNames: string[]) {
    return async (dispatch: Dispatch) => {
        try {
            await amrPipelineService.readIOIsByUser(dealReferenceName, transactionReferenceName, ioisReferenceNames);
        } catch (error) {
            dispatch(errorActions.criticalError(error));
        }
    };
}

function setUnreadIOIsRead(transactionReferenceName: string) {
    return {
        type: actionTypes.SET_UNREAD_IOIS_READ,
        transactionReferenceName,
    };
}


function searchTermChange(searchTerm: string) {
    return {
        type: actionTypes.SEARCH_TERM_CHANGE,
        searchTerm,
    };
}

function storeTransactions(
    transactions: TransactionResponse,
    searchTerm: string,
    tab?: PipelineDetailedTabTypes
) {
    return {
        type: actionTypes.STORE_TRANSACTIONS,
        transactions,
        searchTerm,
        tab
    };
}

function storeInitialTransaction(
    transaction: AmrTransaction | OriginatingTransaction
) {
    return {
        type: actionTypes.STORE_INITIAL_TRANSACTION,
        transaction,
    };
}

function storeTransactionDetails(
    transactionReferenceName: string,
    transaction: OriginatingTransaction | AmrTransaction
) {
    return {
        type: actionTypes.STORE_TRANSACTION_DETAILS,
        transactionReferenceName,
        transaction,
    };
}

function storeDocumentsSearchResults(
    filteredDocuments: OriginatingTransactionDocument[]
) {
    return {
        type: actionTypes.STORE_DOCUMENTS_SEARCH_RESULTS,
        filteredDocuments,
    };
}

function transactionsRequesting(isRequesting: boolean) {
    return {
        type: actionTypes.TRANSACTIONS_REQUESTING_STATE,
        isRequesting,
    };
}

function transactionDetailsRequesting(isRequesting: boolean) {
    return {
        type: actionTypes.TRANSACTION_DETAILS_REQUESTING_STATE,
        isRequesting,
    };
}

function ioisSubmitting(isSubmitting: boolean) {
    return {
        type: actionTypes.TRANSACTION_DETAILS_IOIS_SUBMITTING,
        isSubmitting,
    };
}

function reset() {
    return {
        type: actionTypes.RESET,
    };
}

function logTabAccess(dealReferenceName: string, transactionReferenceName: string, tabAccessType: TransactionAccessType) {
    return async () => {
        try {
            await amrPipelineService.logTabAccess(dealReferenceName, transactionReferenceName, tabAccessType);
        } catch (error) {
            logger.exception(error, 'Error tracking transaction tab access');
        }
    };
}
